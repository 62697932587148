import React, { useEffect } from 'react'
import { useState } from "react";
import AddressModal from '../../components/address-modal/addressModal';
import DeleteModal from '../../components/delete-modal/deleteModal';
import './editprofile.css';
import { Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { updatecustomerprofile, getcustomeraddress, getcustomerprofile } from '../../utils/api';
import toast from 'react-hot-toast';
import Loader from '../../components/loader/loader';

const EditProfile = ({ isOpen, onClose, isDeleteOpen, OnDeleteClose }) => {
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userData')));
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [selectedaddress, setSelectedAddress] = useState(null);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const openDeleteModal = (obj) => {
        setSelectedAddress(obj);
        setIsDeleteModalOpen(true);
    };

    const closeModal = () => {
        setSelectedAddress(null);
        fetchData();
        setIsModalOpen(false);
    };
    const closeDeleteModal = () => {
        fetchData();
        setIsDeleteModalOpen(false);
    };


    const editProfileFormik = useFormik({
        initialValues: {
            name: '',
            email: '',
            mobile: '',
            alternate_mobile: '',
            customer_id: userInfo?.customer_id
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().required('Email is required').matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Email is invalid'),
            alternate_mobile: Yup.string().matches(/^\d{10}$/, 'Alternate Mobile number must be 10 digits').notRequired()
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            setLoading(true);
            try {
                const response = await updatecustomerprofile(values);
                if (response?.success) {
                    toast.success(response?.message);
                } else {
                    toast.error(response?.message);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('API error:', error);
            }
            setSubmitting(false);
        }
    });

    const fetchData = async () => {
        const response = await getcustomeraddress(userInfo?.customer_id);
        if (response?.success) {
            setAddresses(response?.addresses)
        } else {
            toast.error(response?.message);
        }
        const response1 = await getcustomerprofile(userInfo?.customer_id);
        if (response1?.success) {
            setLoading(false);
            editProfileFormik.setValues({
                ...editProfileFormik.values,
                name: response1?.customer?.name,
                email: response1?.customer?.email,
                mobile: response1?.customer?.mobile,
                alternate_mobile: response1?.customer?.alternate_mobile,
                customer_id: response1?.customer?.customer_id
            });
        } else {
            toast.error(response?.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const openEditModal = (obj) => {
        setSelectedAddress(obj);
        setIsModalOpen(true);
    };

    return (
        <>
            {loading && <Loader />}
            {isModalOpen && <AddressModal isOpen={isModalOpen} onClose={closeModal} selectedaddress={selectedaddress} />}
            {isDeleteModalOpen && <DeleteModal isDeleteOpen={isDeleteModalOpen} OnDeleteClose={closeDeleteModal} selectedaddress={selectedaddress} />}
            <section className="contact-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="section-title-wrap mt-4 mb-5">
                            <h4 className="section-title">My Profile</h4>
                        </div>

                        <div className="col-lg-5 col-12 mx-auto mb-5">

                            <p>Please enter your personal details. Worry not, they are safe with us.

                            </p>
                            <form className="custom-form contact-form" onSubmit={editProfileFormik.handleSubmit} autoComplete={'off'}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating">
                                            <input type="text" name="name" id="name" className="form-control" placeholder="Name*" required=""
                                                onChange={editProfileFormik.handleChange}
                                                value={editProfileFormik?.values?.name || ''} />

                                            <label htmlFor="floatingInput">Name*</label>
                                            <span className="error-message">
                                                {editProfileFormik.touched.name && editProfileFormik.errors.name ? (
                                                    <div className="error">{editProfileFormik.errors.name}</div>
                                                ) : null}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating">
                                            <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*"
                                                className="form-control" placeholder="Email" onChange={editProfileFormik.handleChange}
                                                value={editProfileFormik?.values?.email || ''} />

                                            <label htmlFor="floatingInput">Email*</label>
                                            <span className="error-message">
                                                {editProfileFormik.touched.email && editProfileFormik.errors.email ? (
                                                    <div className="error">{editProfileFormik.errors.email}</div>
                                                ) : null}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating">
                                            <input type="text" name="mobile" id="mobile" className="form-control"
                                                placeholder="Mobile Number" required="" readOnly={true}
                                                onChange={editProfileFormik.handleChange}
                                                value={editProfileFormik?.values?.mobile || ''} />

                                            <label htmlFor="floatingInput">Mobile Number*</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating">
                                            <input type="text" name="alternate_mobile" id="alternate_mobile" className="form-control"
                                                placeholder="Alternate Mobile Number" onChange={editProfileFormik.handleChange}
                                                value={editProfileFormik?.values?.alternate_mobile || ''} />

                                            <label htmlFor="floatingInput">Alternate Mobile Number</label>
                                            <span className="error-message">
                                                {editProfileFormik.touched.alternate_mobile && editProfileFormik.errors.alternate_mobile ? (
                                                    <div className="error">{editProfileFormik.errors.alternate_mobile}</div>
                                                ) : null}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-12 me-auto">
                                        <button type="submit" className="form-control">Save</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6 col-12 mx-auto">
                            <div className='row mb-3'>
                                <div className='col-md-6'>
                                    <div className='col-md-12'>
                                        <h6>Saved Address
                                        </h6>
                                    </div>
                                </div>
                                <div className='col-md-6 text-right'>
                                    <div className=""><button className="btn custom-btn addnewAddress" onClick={openModal}>+ Add New</button></div>
                                </div>
                            </div>
                            <div className='row'>
                                {addresses.length > 0 && addresses.map((add, index) => (
                                    <div className='col-md-6 mb-3' key={add?.id}>
                                        <div className="custom-block" style={{ padding: "12px" }}>
                                            <div className="text_contentAdd mt-3">
                                                <p><b><i className={`bi bi-${add?.address_type == 'Home' ? 'house' : 'building'}`} style={{ color: "#7e57c2" }}></i> {add?.address_type}</b></p>
                                                {add?.address_no && <p className='mb-0'>{add?.address_no}
                                                    {add?.landmark && <span>, {add?.landmark}</span>}</p>}
                                                <p>{add?.locality && <span>{add?.locality},</span>} {add?.city}, {add?.state}, {add?.pincode}</p>

                                                <div className="text_contentAdd">
                                                    <button className='button-like-link me-3' onClick={() => { openEditModal(add); }}>Edit</button>
                                                    <button className='button-like-link deletelink' onClick={() => { openDeleteModal(add); }}>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {addresses.length == 0 && (<p>No Saved Addresses</p>)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditProfile;