import React, { useEffect, useState } from 'react';
import bin from '../../assets/images/icon/bin.png';
import { deleteaddress } from '../../utils/api';
import toast from 'react-hot-toast';


import './deleteModal.css';

const DeleteModal = ({ isDeleteOpen, OnDeleteClose, selectedaddress }) => {

    const handleDeleteClose = () => {
        OnDeleteClose();
    };

    const deleteAddressMethod = async () => {
        const obj = {
            id: selectedaddress?.id
        };
        const response = await deleteaddress(obj);
        if (response?.success) {
            toast.success(response?.message);
            OnDeleteClose();
        } else {
            toast.error(response?.message);
        }
    };


    return (
        <div className={`modal ${isDeleteOpen ? 'is-active' : ''}`}>
            <div className="modal-background"></div>

            <div className="modal-content sell-now-Model">
                <button className="modal-close is-large" aria-label="close" onClick={handleDeleteClose}>
                    <i className="bi bi-x-circle AddresClose-icon"></i>

                </button>

                <div className='row'>
                    <div className='col-md-12 col-sm-12 ' style={{ padding: "60px" }}>
                        <div className='text-center'>
                            <h6> Are you sure you want to delete ? </h6>
                            <img src={bin} className="mt-3" style={{ height: "auto" }} />
                        </div>
                        <div className='row text-center mt-5'>
                            <div className='col-md-6 col-6'>
                                <button className="btn  delete-btn custom-border-btn smoothscroll" onClick={() => { deleteAddressMethod(); }}>Delete</button>

                            </div>
                            <div className='col-md-6 col-6'>
                                <button className="btn  cancel-btn custom-border-btn smoothscroll" onClick={handleDeleteClose}>Cancel </button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default DeleteModal;
