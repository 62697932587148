import React, { useEffect, useState } from 'react';
import './addressModal.css';
import { fetchPincodes, addaddress, editaddress, getcities } from '../../utils/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import Loader from '../loader/loader';




const AddressModal = ({ isOpen, onClose, selectedaddress = null }) => {
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userData')));
    const [loading, setLoading] = useState(false);
    const [addressId, setAddressId] = useState(selectedaddress?.id);
    const [addressTypeValue, setAddressTypeValue] = useState(selectedaddress?.address_type);
    const [addressNoValue, setAddressNoValue] = useState(selectedaddress?.address_no);
    const [localityValue, setLocalityValue] = useState(selectedaddress?.locality);
    const [landmarkValue, setLandmarkValue] = useState(selectedaddress?.landmark);
    const [cityValue, setCityValue] = useState(selectedaddress?.city);
    const [stateValue, setStateValue] = useState(selectedaddress?.state);
    const [pincodeValue, setPincodeValue] = useState(selectedaddress?.pincode);
    const [isDefault, setIsDefault] = useState(selectedaddress?.is_default);
    const [pinCodeData, setPinCodeData] = useState([]);
    const [cityData, setCityData] = useState([]);

    const [searchPinCode, setSearchPinCode] = useState([]);

    useEffect(() => {
        const savedCity = localStorage.getItem('selectedCity');
        if (selectedaddress == null && savedCity != null) {
            setCityValue(savedCity);
            addAddressFormik.setFieldValue("city", savedCity);
        }
    }, []);

    const handlePinCodeChange = (event) => {
        const { name, value } = event.target;
        const filteredSuggestions = pinCodeData?.filter(item =>
            item?.pincode.includes(value)
        );
        setSearchPinCode(filteredSuggestions);
        setPincodeValue(value?.pincode);
    };

    const handlePinCodeClick = (value) => {
        addAddressFormik.setFieldValue("state", value?.state);
        addAddressFormik.setFieldValue("pincode", value?.pincode);
        addAddressFormik.setFieldValue("locality", value?.locality);
        addAddressFormik.setFieldValue("city", value?.city);
        setPincodeValue(value?.pincode);
        setLocalityValue(value?.locality);
        setCityValue(value?.city);
        setStateValue(value?.state);
        setSearchPinCode([]);
    };

    const addAddressFormik = useFormik({
        initialValues: {
            customer_id: userInfo?.customer_id, address_type: addressTypeValue, address_no: addressNoValue, locality: localityValue,
            landmark: landmarkValue, lat: '', lng: '', city: cityValue, state: stateValue, pincode: pincodeValue, id: addressId, is_default: isDefault
        },
        validationSchema: Yup.object({
            address_type: Yup.string().required('Address Type is required'),
            address_no: Yup.string().required('Flat no./H no./Office is required'),
            locality: Yup.string().required('Locality is required'),
            city: Yup.string().required('City is required'),
            state: Yup.string().required('State is required'),
            pincode: Yup.string().required('Pincode is required')
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            setLoading(true);
            try {
                if (addressId) {
                    const response = await editaddress(values);
                    if (response?.success) {
                        addAddressFormik.resetForm();
                        toast.success(response?.message);
                        onClose();
                    } else {
                        toast.error(response?.message);
                    }
                }
                else {
                    const response = await addaddress(values);
                    if (response?.success) {
                        addAddressFormik.resetForm();
                        toast.success(response?.message);
                        onClose();
                    } else {
                        toast.error(response?.message);
                    }
                }

            } catch (error) {
                console.error('API error:', error);
            }
            setLoading(false);
            setSubmitting(false);
        }
    });

    const handleClose = () => {
        addAddressFormik.resetForm();
        onClose();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        addAddressFormik.setFieldValue(name, value);
        if (name == "address_type") {
            setAddressTypeValue(value);
        }
        if (name == "locality") {
            setLocalityValue(value);
        }
        else if (name == "address_no") {
            setAddressNoValue(value);
        }
        else if (name == "landmark") {
            setLandmarkValue(value);
        }

    };


    useEffect(() => {
        addAddressFormik.validateForm();
    }, [addAddressFormik.values]);


    const fetchCity = async () => {
        try {
            const response = await getcities();
            setCityData(response?.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };


    const fetchData = async (cityValue) => {
        try {
            const response = await fetchPincodes(cityValue);
            setPinCodeData(response?.pincodes);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCity();
    }, []);

    useEffect(() => {
        if (cityValue) {
            console.log(cityValue);
            fetchData(cityValue);
        }


    }, [cityValue]);


    return (
        <>
            {loading && <Loader />}
            <div className={`modal ${isOpen ? 'is-active' : ''}`}>
                <div className="modal-background"></div>

                <div className="modal-content sell-now-Model">
                    <button className="modal-close is-large" aria-label="close" onClick={handleClose}>
                        <i className="bi bi-x-circle AddresClose-icon"></i>

                    </button>

                    <div className='row'>
                        <div className='col-md-12 col-sm-12' >
                            <form className="custom-form contact-form" onSubmit={addAddressFormik.handleSubmit} autoComplete="off">
                                <div className="box" style={{ padding: "20px" }}>
                                    <div style={{ marginBottom: '50px' }}>
                                        <h4 className="title">Address</h4>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4">
                                            <div>
                                                <input type="radio" className='me-2' id="home" name="address_type" onChange={handleInputChange} value="Home" checked={addressTypeValue === 'Home'} />
                                                <label htmlFor="home" className='pointer'>
                                                    <p className='mb-0'>Home</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div>
                                                <input type="radio" className='me-2' id="office" name="address_type" onChange={handleInputChange} value="Office" checked={addressTypeValue === 'Office'} />
                                                <label htmlFor="office" className='pointer'>
                                                    <p className='mb-0'>Office</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div>
                                                <input type="radio" className='me-2' id="other" name="address_type" onChange={handleInputChange} value="Other" checked={addressTypeValue === 'Other'} />
                                                <label htmlFor="other" className='pointer'>
                                                    <p className='mb-0'>Other</p>
                                                </label>
                                            </div>
                                        </div>
                                        <span className="error-message">
                                            {addAddressFormik.touched.address_type && addAddressFormik.errors.address_type ? (
                                                <div className="error">{addAddressFormik.errors.address_type}</div>
                                            ) : null}
                                        </span>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div className="form-floating autocomplete">
                                                <input
                                                    name="pincode" id="pincode" className="form-control" placeholder="Enter Pincode*" value={pincodeValue} autoComplete="off"
                                                    onChange={(e) => handlePinCodeChange(e)}
                                                />
                                                <ul id="suggestions-list autocomplete-dropdown" >
                                                    {searchPinCode.map((spincode, index) => (
                                                        <li key={index} onClick={() => handlePinCodeClick(spincode)}>
                                                            {spincode?.pincode}, {spincode?.city}, {spincode?.state}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <label htmlFor="pincode">Pincode*</label>
                                                <span className="error-message">
                                                    {addAddressFormik.touched.pincode && addAddressFormik.errors.pincode ? (
                                                        <div className="error">{addAddressFormik.errors.pincode}</div>
                                                    ) : null}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div className="form-floating">
                                                <input type="text" name="locality" id="locality" className="form-control" placeholder="Enter Locality*" required="" value={localityValue} onChange={handleInputChange} />
                                                <label htmlFor="locality">Locality*</label>
                                                <span className="error-message">
                                                    {addAddressFormik.touched.locality && addAddressFormik.errors.locality ? (
                                                        <div className="error">{addAddressFormik.errors.locality}</div>
                                                    ) : null}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-floating">
                                                <input type="text" name="address_no" id="address_no" className="form-control" placeholder="Enter Flat no./H no./Office*"
                                                    required="" value={addressNoValue} onChange={handleInputChange} />
                                                <label htmlFor="address_no">Flat no./H no./Office*</label>
                                                <span className="error-message">
                                                    {addAddressFormik.touched.address_no && addAddressFormik.errors.address_no ? (
                                                        <div className="error">{addAddressFormik.errors.address_no}</div>
                                                    ) : null}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-floating">
                                                <input type="text" name="landmark" id="landmark" className="form-control" placeholder="Enter Landmark (optional)" value={landmarkValue} onChange={handleInputChange} />
                                                <label htmlFor="landmark">Landmark (optional)</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div className="form-floating">
                                                <select
                                                    name="city"
                                                    id="city"
                                                    className="form-control"
                                                    required
                                                    value={addAddressFormik.values.city}
                                                    onChange={addAddressFormik.handleChange}
                                                    onBlur={addAddressFormik.handleBlur} // Track if the field is blurred for validation
                                                >
                                                    <option value="" label="Select City" />
                                                    {cityData?.map((city) => (
                                                        <option key={city?.city_id} value={city?.city_name} label={city?.city_name} />
                                                    ))}
                                                </select>
                                                <label htmlFor="city">City*</label>
                                                <span className="error-message">
                                                    {addAddressFormik.touched.city && addAddressFormik.errors.city ? (
                                                        <div className="error">{addAddressFormik.errors.city}</div>
                                                    ) : null}
                                                </span>
                                            </div>

                                        </div>

                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div className="form-floating">
                                                <input type="text" name="state" id="state" className="form-control" placeholder="Enter State" required="" value={stateValue} readOnly={true} />
                                                <label htmlFor="state">State*</label>
                                                <span className="error-message">
                                                    {addAddressFormik.touched.state && addAddressFormik.errors.state ? (
                                                        <div className="error">{addAddressFormik.errors.state}</div>
                                                    ) : null}
                                                </span>
                                            </div>
                                        </div>
                                        {/* ${!addAddressFormik.isSubmitting ? 'disabled' : ''} */}
                                        <div className="col-lg-4 col-12 ms-auto"><button type="submit" className={`form-control ${!addAddressFormik.isSubmitting ? 'disabled' : ''} `}>Submit</button></div>
                                    </div>
                                </div>

                            </form>


                        </div>

                    </div>

                </div>
            </div>
        </>

    );
};

export default AddressModal;
