import React, { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getsellnowoder, cancelorderbycustomer } from '../../utils/api';
import MobileImg from '../../assets/images/icon/mobile.svg';
import { modelImagePath } from '../../utils/constants';
import moment from 'moment';
import { useEffect } from 'react';
import DeviceReportData from '../../components/device-report/DeviceReport';

const DeviceReports = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { ref_no } = useParams();
    const [enquiryResponse, setEnquiryResponse] = useState(null);
    const [deviceInfo, setDeviceInfo] = useState(null);
    const fetchData = async () => {
        try {
            const response = await getsellnowoder(ref_no);
            if (response?.success) {
                setEnquiryResponse(response?.enquiry);
                if(response?.enquiry?.PartnerDeviceInfo){
                    setDeviceInfo(response?.enquiry?.PartnerDeviceInfo);
                }
                else{
                    setDeviceInfo(response?.enquiry?.DeviceInfo);
                }
            } else {
                // toast.error(response?.message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching sell mobile data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
            <div className='container'>
                <div className=' row justify-content-center pt-5' style={{ width: "100%" }}>
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <h4 className="">Device Report </h4>
                        </div>
                    </div>
                </div>
                <div className=' row  p-2' style={{ width: "100%" }}>
                    <div className="col-md-12 ">
                        <div className="" style={{ marginBottom: "10px" }} >
                            <div className='row'>
                                <div className="custom-block-info col-md-12">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <h6 className='mt-0 mb-1' style={{ fontSize: "18px" }}>Model: <span style={{ color: "#918c8c" }}>{enquiryResponse?.Model}</span></h6>
                                            <h6 className='mb-2 mb-1' style={{ fontSize: "18px" }}>Variant: <span style={{ color: "#918c8c" }}>{enquiryResponse?.VariantInfo.toUpperCase()}</span></h6>
                                        </div>
                                        <div className='col-md-6'>
                                            <h6 className='mt-0 mb-1 report-date-time' style={{ fontSize: "18px" }}><span>Report Date:</span> <span style={{ color: "#918c8c" }}>{moment(enquiryResponse?.TransactionDate).format("MMMM Do, YYYY, h:mm A")}</span> </h6>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className='container pt-5' >
                <DeviceReportData selectedModel={deviceInfo} />
            </div>


        </>

    )
}

export default DeviceReports;